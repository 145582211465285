import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import { useRouter } from "next/router";
import { useMutation, useSubscription } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setMenu } from "../../Redux/Actions/sidebarAction";
import { triggerDelightedSurveyForCurrentUser } from "../../utils/delighted";
import {
  ENDORSEMENT_RESOLVED_SUBSCRIPTION,
  QUOTE_QUOTED_SUBSCRIPTION,
  QUOTE_ERROR_SUBSCRIPTION,
} from "../../pages/api/subscriptions";
import { MagicQuoteModal } from "../Modals/MagicQuoteModal";
import { GET_SINGLE_APPLICATION } from "../../pages/api/mutations";
import { updateApplicationAction } from "../../Redux/Actions/quoteActions";
import { selectActivePolicies } from "../../Redux/Selectors/policySelectors";

export default function SideBar({ callback, isCustomCheck, setIsCustom }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const sideMenu = useSelector((state) => state.sideBarMenu.menu);
  const activePolicies = useSelector(selectActivePolicies);
  const { showPolicesOrQuotes } = useSelector((state) => state.quoteReducer);
  const [quoteCarrier, setQuotedQuoteCarrier] = useState("");
  const [getSingleApplication] = useMutation(GET_SINGLE_APPLICATION);

  const [open, setOpen] = useState(true);
  const [openMagicModal, setOpenMagicModal] = useState(false);
  const [quoteErrorMessage, setQuoteErrorMessage] = useState(null);

  const successToast = (message) => dispatch(successToast(message));
  const errorToast = (message) => dispatch(errorToast(message));

  useEffect(() => {
    if (isCustomCheck) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isCustomCheck]);

  let companyId = user?.company?.id;

  const {
    data: qData,
    loading: qLoading,
    error: qError,
  } = useSubscription(QUOTE_QUOTED_SUBSCRIPTION, {
    variables: {
      companyId,
    },
  });
  const {
    data: qeData,
    loading: qeLoading,
    error: qeError,
  } = useSubscription(QUOTE_ERROR_SUBSCRIPTION, {
    variables: {
      companyId,
    },
  });

  useEffect(() => {
    const onCompleted = async (data) => {
      if (data && data.QuoteQuotedUpdateSubscription) {
        setQuoteErrorMessage(null);
        // dispatch(infoToast("Your progressive quote is here !"));

        if (data?.QuoteQuotedUpdateSubscription?.payload?.appId) {
          const appInfo = await getSingleApplication({
            variables: {
              appId: data.QuoteQuotedUpdateSubscription.payload.appId,
            },
          });
          if (appInfo.data.fetchSingleApplication) {
            dispatch(
              updateApplicationAction(appInfo.data.fetchSingleApplication)
            );
          }
        }
        if (
          data?.QuoteQuotedUpdateSubscription?.payload?.quoteCarrier.includes(
            "Progressive"
          )
        ) {
          setQuotedQuoteCarrier("Progressive");
          setOpenMagicModal(true);
        } else if (
          data?.QuoteQuotedUpdateSubscription?.payload?.quoteCarrier.includes(
            "Coverwhale"
          )
        ) {
          setQuotedQuoteCarrier("Coverwhale");
          setOpenMagicModal(true);
        }
      }
    };

    const onError = (mvrError) => {
      /* magic */
    };
    if (onCompleted || onError) {
      if (onCompleted && !qLoading) {
        onCompleted(qData);
      } else if (onError && !qLoading && qError) {
        onError(qError);
      }
    }
  }, [qLoading, qData, qError]);

  useEffect(() => {
    const onCompleted = async (data) => {
      if (data && data.QuoteErrorSubscription) {
        // dispatch(infoToast("Your progressive quote is here !"));

        if (data?.QuoteErrorSubscription?.payload?.appId) {
          const appInfo = await getSingleApplication({
            variables: {
              appId: data.QuoteErrorSubscription.payload.appId,
            },
          });
          if (appInfo.data.fetchSingleApplication) {
            dispatch(
              updateApplicationAction(appInfo.data.fetchSingleApplication)
            );
          }
        }
        if (
          data?.QuoteErrorSubscription?.payload?.quoteCarrier.includes(
            "Progressive"
          )
        ) {
          setQuotedQuoteCarrier("Progressive");
          setQuoteErrorMessage(
            data?.QuoteErrorSubscription?.payload?.declinedReason
          );
          setOpenMagicModal(true);
        } else if (
          data?.QuoteErrorSubscription?.payload?.quoteCarrier.includes(
            "Coverwhale"
          )
        ) {
          setQuotedQuoteCarrier("Coverwhale");
          setQuoteErrorMessage(
            data?.QuoteErrorSubscription?.payload?.declinedReason
          );
          setOpenMagicModal(true);
        }
      }
    };

    const onError = (err) => {
      /* magic */
    };
    if (onCompleted || onError) {
      if (onCompleted && !qeLoading) {
        onCompleted(qeData);
      } else if (onError && !qeLoading && qeError) {
        onError(qeError);
      }
    }
  }, [qeLoading, qeData, qeError]);

  const handleSubMenuClick = (mainItem) => {
    if (!open && mainItem.title === "Assets") {
      setOpen(true);
      callback(!open);
    }

    let copySidebar = [...sideMenu];

    let updatedSidebar = copySidebar?.map((item) => {
      if (item.id === mainItem.id && item.title === "Assets") {
        return {
          ...item,
          isOpen: !item.isOpen,
          subMenu: [
            {
              subTitle: "Vehicles",
              isActive: true,
            },
            {
              subTitle: "Drivers",
              isActive: false,
            },
            {
              subTitle: "Commodities",
              isActive: false,
            },
          ],
        };
      } else if (item.id === mainItem.id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    dispatch(setMenu(updatedSidebar));

    if (mainItem.title === "Dashboard") {
      router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD);
    } else if (mainItem.title === "Assets") {
      router.push(process.env.NEXT_PUBLIC_USER_VEHICLE);
    } else if (mainItem.title === "Policies") {
      if (activePolicies.length === 0) {
        router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD_QUOTES);
      } else {
        router.push(process.env.NEXT_PUBLIC_USER_POLICY);
      }
    } else if (mainItem.title === "Documents") {
      router.push(process.env.NEXT_PUBLIC_USER_DOCUMENTS);
    } else if (mainItem.title === "Settings") {
      router.push(process.env.NEXT_PUBLIC_SETTINGS);
    } else if (mainItem.title === "Dispatchers") {
      router.push(process.env.NEXT_PUBLIC_SETTINGS_DISPATCHER);
    } else if (mainItem.title === "Quotes") {
      router.push(process.env.NEXT_PUBLIC_USER_QUOTES);
    } else if (mainItem.title === "Referral") {
      router.push(process.env.NEXT_PUBLIC_USER_REFERRAL);
    }
  };

  const onClickSubMenu = (parentIndex, subMenuIndex) => {
    const updatedMenu = [...sideMenu];
    const updatedSubMenu = updatedMenu[parentIndex].subMenu?.map(
      (item, index) => ({
        ...item,
        isActive: index === subMenuIndex,
      })
    );
    updatedMenu[parentIndex] = {
      ...updatedMenu[parentIndex],
      subMenu: updatedSubMenu,
    };

    if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Vehicles"
    ) {
      router.push(process.env.NEXT_PUBLIC_USER_VEHICLE);
    } else if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Drivers"
    ) {
      router.push(process.env.NEXT_PUBLIC_USER_DRIVER);
    } else if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle === "Commodities"
    ) {
      router.push(process.env.NEXT_PUBLIC_USER_COMMODITY);
    }

    dispatch(setMenu(updatedMenu));
  };

  const closeQuotePopUpsModal = (value) => {
    setQuoteErrorMessage(null);
    setOpenMagicModal(value);
  };
  return (
    <div
      className={` flex flex-col  h-full bg-gray4 rounded-r-lg fixed z-30 flex-wrap  ${
        open ? " w-64" : "w-20"
      } transition-all duration-300 xs:hidden `}
    >
      <img
        src={`${!open ? "/Images/Tab-open.webp" : "/Images/Tab-close.webp"}`}
        onClick={() => {
          setOpen(!open);
          callback(!open);
          // setMenuItems(Menu);
          setIsCustom(false);
        }}
        alt="truck"
        className={` absolute cursor-pointer -right-10 top-3 w-12 h-12`}
      />

      <div className="mt-20">
        <ul className="flex flex-col  " style={{ height: "85vh" }}>
          {sideMenu?.map((item, i) => {
            if (
              (!showPolicesOrQuotes && item.title === "Policies") ||
              (!showPolicesOrQuotes && item.title === "Quotes")
            ) {
              return;
            }
            return (
              <>
                <li
                  key={i}
                  className={` ${
                    i === sideMenu?.length - 1 ? " mt-auto" : ""
                  }  text-white cursor-pointer mt-5 ${
                    item.isOpen && open && "onhover"
                  }  relative  `}
                  onClick={() => handleSubMenuClick(item)}
                >
                  {item.isOpen ? (
                    <div
                      className={` bg-blue5 w-2 h-8 rounded-l-none rounded-r-md absolute  ${
                        open ? "-left-5" : "left-0"
                      } -top-1  `}
                    />
                  ) : null}
                  <div
                    className={`flex items-center space-x-4 ${
                      !open ? "ml-6" : "ml-10"
                    }`}
                  >
                    <Icon name={item.icon} />
                    <h3
                      className={`${!open && "hidden"} ${
                        item.isOpen ? "title-sidebar-show" : "title-sidebar"
                      } `}
                    >
                      {" "}
                      {item.title}
                    </h3>
                    {item.subMenu && open ? (
                      <Icon
                        name={
                          item.isOpen ? "icon-close-menu" : "icon-open-menu"
                        }
                      />
                    ) : null}
                  </div>
                </li>

                {item.subMenu && open ? (
                  <ul
                    className={`subMenu ${
                      item.isOpen && "showMenu"
                    } flex flex-col `}
                  >
                    {item.subMenu?.map((subMenu, subIndex) => {
                      return (
                        <li
                          key={subIndex}
                          onClick={() => onClickSubMenu(i, subIndex)}
                          className={`relative  text-white  mt-3 cursor-pointer    ml-24`}
                        >
                          <div className={`flex items-center `}>
                            <h3
                              className={`${
                                subMenu.isActive && " subMenuActive  "
                              }`}
                            >
                              {subMenu.subTitle}
                            </h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </>
            );
          })}
        </ul>
      </div>
      {openMagicModal && (
        <MagicQuoteModal
          open={openMagicModal}
          setOpenMagicModal={closeQuotePopUpsModal}
          quoteCarrier={quoteCarrier}
          quoteErrorMessage={quoteErrorMessage}
        />
      )}
    </div>
  );
}
