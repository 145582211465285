import moment from "moment-mini";
import { numberFormater } from "../../utils/numberFormate";

export const getActivePolicies = (policies, type) => {
  if (type === "active") {
    const activePolicies = [];
    policies &&
      policies.forEach((p) => {
        if (p.status === "Reinstated" || p.status === "Active") {
          activePolicies.push(p);
        }
        // else if (p.status === "Reinstated" ||
        //   p.status === "Active") {
        //   activePolicies.push(p);
        // }
      });
    return activePolicies;
  } else if (type === "previous") {
    const previousPolicies = [];
    policies.forEach((p) => {
      if (p.status !== "Reinstated" && p.status !== "Active") {
        if (p.status === "Renewed" && moment(p.quote.expirationDate) < moment(new Date().toISOString())) {
          const prevPolicy = {
            id: p.id,
            policyType:
              p.lob === "MTC"
                ? "Motor truck Cargo"
                : p.lob === "AL"
                ? "Auto Liability"
                : p.lob === "PD"
                ? "Physical Damage"
                : p.lob === "GL"
                ? "General Liability"
                : p.lob === "NTL"
                ? "Non Trucking Liability"
                : p.lob,
            startEnd: `${moment(p.quote ? p.quote.effectiveDate : policy.effectiveDate).format(
              "MM/DD/YYYY"
            )} - ${moment(p.quote ? p.quote.expirationDate : policy.expirationDate).format("MM/DD/YYYY")}`,
            limit: numberFormater(p.quote.limit),
            deductible: numberFormater(p.quote.deductible),
            quote: p.quote,
            package: p.package,
          };
          previousPolicies.push(prevPolicy);
        } else {
          const prevPolicy = {
            id: p.id,
            policyType:
              p.lob === "MTC"
                ? "Motor truck Cargo"
                : p.lob === "AL"
                ? "Auto Liability"
                : p.lob === "PD"
                ? "Physical Damage"
                : p.lob === "GL"
                ? "General Liability"
                : p.lob === "NTL"
                ? "Non Trucking Liability"
                : p.quote.lob,
            startEnd: `${moment(p.quote ? p.quote.effectiveDate : policy.effectiveDate).format(
              "MM/DD/YYYY"
            )} - ${moment(p.quote ? p.quote.expirationDate : policy.expirationDate).format("MM/DD/YYYY")}`,
            limit: numberFormater(p.quote.limit),
            deductible: numberFormater(p.quote.deductible),
            quote: p.quote,
            package: p.package,
          };
          previousPolicies.push(prevPolicy);
        }
      }
    });
    return previousPolicies;
  }
};

export const activePoliciesTotalPaid = (policies) => {
  const activePolicies = getActivePolicies(policies, "active");
  const packageIds = [];
  const uniquePackages = [];
  activePolicies.forEach((p) => {
    if (!packageIds.includes(p.package.id)) {
      uniquePackages.push(p.package);
      packageIds.push(p.package.id);
    }
  });
  const policyTotalPaid = uniquePackages.reduce((acc, p) => {
    return acc + p.totalAmountPaid;
  }, 0);
  return policyTotalPaid;
};

export const activePoliciesTotal = (policies) => {
  const activePolicies = getActivePolicies(policies, "active");
  const packageIds = [];
  const uniquePackages = [];
  activePolicies.forEach((p) => {
    if (!packageIds.includes(p.package.id)) {
      uniquePackages.push(p.package);
      packageIds.push(p.package.id);
    }
  });
  const policyTotal = uniquePackages.reduce((acc, p) => {
    return acc + p.totalPremium;
  }, 0);
  return policyTotal;
};

export const updatePolicyInState = (state, payload) => {
  const _policies = [...state.policies];
  const index = _policies.findIndex((p) => p.id == payload.id);
  if (index > -1) {
    _policies[index].lobAvailability = false;
  }
  return { ...state, policies: _policies };
};

export const updatePolicyForFile = (state, payload) => {
  const _policies = [...state.policies];
  const index = _policies.findIndex((p) => p.id == payload.id);
  if (index > -1) {
    payload.Files.forEach((file) => {
      _policies[index].File.push(file);
    });
  }

  return { ...state, policies: _policies };
};

export const updatePoliciesOnNotification = (state, payload) => {
  const _policies = [...state.policies];
  const index = _policies.findIndex((p) => p.id == payload.id);
  if (index > -1) {
    _policies[index] = payload;
  } else {
    _policies.push(payload);
  }
  return { ...state, policies: _policies };
};

export const setAndTransformPolicies = (state, payload) => {
  const _policies = [...payload];
  const _transformedPolicies = _policies?.map((policy) => {
    let lobChange = false;
    let additionalInsuredRequest = false;
    // get active endorsements
    const _activeEndorsements = policy.endorsments?.filter((e) => e.status == "Open");

    // get LOB change operation from endorsements
    _activeEndorsements.forEach((endorsement) => {
      const lobsChangeOperation = endorsement.Operations?.filter(
        (o) => (o.action == "AddPolicyLobs" || o.action == "RemovePolicyLobs") && o.subject == "Policy"
      );
      if (lobsChangeOperation?.length > 0) {
        lobChange = true;
      }
      const additionalInsuredOperation = endorsement.Operations?.filter(
        (o) => o.action == "AdditionalInsured" && o.subject == "Policy"
      );
      if (additionalInsuredOperation?.length > 0) {
        additionalInsuredRequest = true;
      }
    });

    return {
      ...policy,
      lobChange,
      additionalInsuredRequest,
      lobAvailability: policy.lob ? lobsAvailabilityCheck(policy.lob) : lobsAvailabilityCheck(policy.quote.lob),
      otherLobs: policy.lob
        ? lobsAvailabilityCheck(policy.lob) && !lobChange
          ? policyOtherLobs(policy.lob)
          : []
        : lobsAvailabilityCheck(policy.quote.lob) && !lobChange
        ? policyOtherLobs(policy.quote.lob)
        : [],
    };
  });

  return { ...state, policies: _transformedPolicies };
};

const lobsAvailabilityCheck = (lobs) => {
  if (
    lobs.includes("AL") &&
    lobs.includes("PD") &&
    lobs.includes("MTC") &&
    lobs.includes("NTL") &&
    lobs.includes("GL")
  ) {
    return false;
  } else {
    return true;
  }
};

const policyOtherLobs = (lobs) => {
  const otherLobs = [];
  if (!lobs.includes("AL")) {
    otherLobs.push("Auto Liability");
  }
  if (!lobs.includes("PD")) {
    otherLobs.push("Physical Damage");
  }
  if (!lobs.includes("MTC")) {
    otherLobs.push("Motor Truck Cargo");
  }
  if (!lobs.includes("GL")) {
    otherLobs.push("General Liability");
  }
  if (!lobs.includes("NTL")) {
    otherLobs.push("Non Trucking Liability");
  }

  return otherLobs;
};
