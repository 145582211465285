const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const numberFormater = (num) => {
  return formatter.format(num);
};

export const formatCurrency = (input) => {
  // Remove any commas and dollar signs
  const cleanedInput = String(input).replace(/[$,]/g, "");

  // Try to parse the cleaned input as a number
  const parsedValue = parseFloat(cleanedInput);

  // Check if the parsed value is a valid number
  if (!isNaN(parsedValue)) {
    // Format the number as currency
    const formattedCurrency = parsedValue.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: parsedValue % 1 === 0 ? 0 : 2, // Keep 2 decimal places if not 00
      maximumFractionDigits: parsedValue % 1 === 0 ? 0 : 2,
    });

    return formattedCurrency;
  } else {
    // If it's not a valid number, return the input as is
    return input;
  }
};