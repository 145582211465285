export const APPEND_NOTIFICATION = "APPEND_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_PAYLOAD = "UPDATE_NOTIFICATION_PAYLOAD";
export const UPDATE_VIEWMORE_LOADER = "UPDATE_VIEWMORE_LOADER";
export const UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE =
  "UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE";
export const SET_HOME_NOTIFICATIONS = "SET_HOME_NOTIFICATIONS";
export const UPDATE_HOME_NOTIFICATION = "UPDATE_HOME_NOTIFICATION";
export const UPDATE_HOME_NOTIFICATION_PAYLOAD =
  "UPDATE_HOME_NOTIFICATION_PAYLOAD";
export const UPDATE_HOME_VIEWMORE_LOADER = "UPDATE_HOME_VIEWMORE_LOADER";

export const SET_LOADING = "SET_LOADING";
export const SUCCESS_TOAST = "SUCCESS_TOAST";
export const ERROR_TOAST = "ERROR_TOAST";
export const INFO_TOAST = "INFO_TOAST";
export const WARN_TOAST = "WARN_TOAST";
export const SET_USER = "SET_USER";

export const SET_DRIVERS = "SET_DRIVERS";
export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const ADD_NEW_DRIVER = "ADD_NEW_DRIVER";
export const DELETE_DRIVER = "DELETE_DRIVER";
export const UPDATE_DRIVER_STATUS = "UPDATE_DRIVER_STATUS";
export const RESTORE_DELETED_DRIVER = "RESTORE_DELETED_DRIVER";
export const SET_NEW_APP_STATUS = "SET_NEW_APP_STATUS";

export const SET_VEHICLES = "SET_VEHICLES";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const ADD_NEW_VEHICLE = "ADD_NEW_VEHICLE";
export const RESTORE_VEHICLE = "RESTORE_VEHICLE";

export const UPDATE_CURRENT_APPLICATION_DATA =
  "UPDATE_CURRENT_APPLICATION_DATA";
export const RESET_APPLICATION_OBJECT = "RESET_APPLICATION_OBJECT";

export const SET_COMMODITIES = "SET_COMMODITIES";
export const UPDATE_COMMODITY = "UPDATE_COMMODITY";
export const DELETE_COMMODITY = "DELETE_COMMODITY";
export const ADD_NEW_COMMODITY = "ADD_NEW_COMMODITY";

export const SET_COMPANY_USERS = "SET_COMPANY_USERS";
export const UPDATE_COMPANY_USER = "UPDATE_COMPANY_USER";
export const DELETE_COMPANY_USER = "DELETE_COMPANY_USER";
export const ADD_NEW_COMPANY_USER = "ADD_NEW_COMPANY_USER";
export const SET_COMPANY_DETAILS = "SET_COMPANY_DETAILS";
export const SET_NOTES_FLAG = "SET_NOTES_FLAG";
export const UPDATE_NOTES_IN_COMPANY = "UPDATE_NOTES_IN_COMPANY";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_DOT = "SET_DOT";
export const SET_CREDIT = "SET_CREDIT";
export const CLEAR_DATA_LT = "CLEAR_DATA_LT";
export const CLEAR_DATA_WITHOUT_USER = "CLEAR_DATA_WITHOUT_USER";

export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";

export const SET_REFRESH = "SET_REFRESH";
export const SET_SIDEBAR = "SET_SIDEBAR";
export const UPDATE_SIDEBAR = "UPDATE_SIDEBAR";
export const SET_LT_SEARCH_DATA = "SET_LT_SEARCH_DATA";
export const SET_LT_SIDEBAR = "SET_LT_SIDEBAR";
export const UPDATE_LT_SIDEBAR = "UPDATE_LT_SIDEBAR";

export const SET_POLICIES = "SET_POLICIES";
export const UPDATE_POLICY = "UPDATE_POLICY";
export const UPDATE_POLICY_FILE = "UPDATE_POLICY_FILE";
export const UPDATE_POLICIES_ON_CREATED_NOTIFICATION =
  "UPDATE_POLICY_ON_CREATED_NOTIFICATION";
export const SET_COMPANY_LOSS_RUNS_DOCUMENTS =
  "SET_COMPANY_LOSS_RUNS_DOCUMENTS";
export const SET_APPLICATION_PERSISTENCE = "SET_APPLICATION_PERSISTENCE";

export const SET_DISPATCHER_COMPANIES = "SET_DISPATCHER_COMPANIES";
export const SET_DISPATCHER_USER = "SET_DISPATCHER_USER";
export const UPDATE_DISPATCHER_COMPANY = "UPDATE_DISPATCHER_COMPANY";
export const UPDATE_DISPATCHER_COMPANIES_PAYLOAD =
  "UPDATE_DISPATCHER_COMPANIES_PAYLOAD";
export const UPDATE_DISPATCHER_VIEWMORE_LOADER =
  "UPDATE_DISPATCHER_VIEWMORE_LOADER";
export const SET_DISPATCHER_COMPANY_SEARCH_RESULTS =
  "SET_DISPATCHER_COMPANY_SEARCH_RESULTS";
export const CLEAR_DISPACTHER_COMPANY_SEARCH_RESULTS =
  "CLEAR_DISPACTHER_COMPANY_SEARCH_RESULTS";

export const SET_COMPANY_DISPATCHERS = "SET_COMPANY_DISPATCHERS";
export const UPDATE_COMPANY_DISPATCHER = "UPDATE_COMPANY_DISPATCHER";

export const SET_LTUSER_COMPANIES = "SET_LTUSER_COMPANIES";
export const SET_LTUSER = "SET_LTUSER";
export const SET_LT_LOGGED_IN = "SET_LT_LOGGED_IN";
export const UPDATE_LTUSER_COMPANIES_PAYLOAD =
  "UPDATE_LTUSER_COMPANIES_PAYLOAD";
export const SET_BACK_PRESSED = "SET_BACK_PRESSED";
export const SET_LTUSER_COMPANY_SEARCH_RESULTS =
  "SET_LTUSER_COMPANY_SEARCH_RESULTS";
export const UPDATE_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD =
  "SET_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD";
export const SET_BACK_PRESSED_WITH_SEARCH = "SET_BACK_PRESSED_WITH_SEARCH";
export const CLEAR_LTUSER_COMPANY_SEARCH_RESULTS =
  "CLEAR_LTUSER_COMPANY_SEARCH_RESULTS";

export const SET_CURRENT_PACKAGE = "SET_CURRENT_PACKAGE";
export const SET_APPLICATIONS = "SET_APPLICATIONS";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const UPDATE_APPLICATION_IN_APPLICATIONS =
  "UPDATE_APPLICATION_IN_APPLICATIONS";

export const UPDATE_APPLICATION_PACKAGE = "UPDATE_APPLICATION_PACKAGE";
export const SHOW_POLICY_OR_QUOTES = "SHOW_POLICY_OR_QUOTES";
export const SET_SETTINGS_TAB_INDEX = "SET_SETTINGS_TAB_INDEX";
export const SET_DASHBOARD_TAB_INDEX = "SET_DASHBOARD_TAB_INDEX";
export const SET_DOCUMENTS_TAB_INDEX = "SET_DOCUMENTS_TAB_INDEX";

export const SET_OPEN_ENDORSEMENTS = "SET_OPEN_ENDORSEMENTS";
export const SET_CLOSE_ENDORSEMENTS = "SET_CLOSE_ENDORSEMENTS";
export const SET_OPEN_ENDORSEMENTS_COUNT = "SET_OPEN_ENDORSEMENTS_COUNT";
export const SET_CLOSE_ENDORSEMENTS_COUNT = "SET_CLOSE_ENDORSEMENTS_COUNT";

// Referrals Types
export const SET_PENDING_REFERRALS = "SET_PENDING_REFERRALS";
export const SET_REJECTED_REFERRALS = "SET_REJECTED_REFERRALS";
export const SET_ELIGIBLE_REFERRALS = "SET_ELIGIBLE_REFERRALS";
export const SET_COMPLETED_REFERRALS = "SET_COMPLETED_REFERRALS";
export const SET_PENDING_REFERRALS_COUNT = "SET_PENDING_REFERRALS_COUNT";
export const SET_REJECTED_REFERRALS_COUNT = "SET_REJECTED_REFERRALS_COUNT";
export const SET_ELIGIBLE_REFERRALS_COUNT = "SET_ELIGIBLE_REFERRALS_COUNT";
export const SET_COMPLETED_REFERRALS_COUNT = "SET_COMPLETED_REFERRALS_COUNT";

export const SET_ELDPROVIDER = "SET_ELDPROVIDER";
export const SET_PLATFORM_FEE_DATA = "SET_PLATFORM_FEE_DATA";
