import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

const Layout2 = ({ children }) => {
  const router = useRouter();
  const [wait, setWait] = useState(true);

  useEffect(() => {
    const mainToken = localStorage.getItem("mainToken");
    const LTtoken = localStorage.getItem("LTtoken");
    const DispatcherToken = localStorage.getItem("DispatcherToken");

    if (mainToken) {
      router.replace(process.env.NEXT_PUBLIC_USER_DASHBOARD);
    } else if (LTtoken) {
      const identifyOldUserSession = localStorage.getItem("LTuserData");

      if (identifyOldUserSession) {
        localStorage.clear();
        router.replace(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
      } else {
        router.replace(process.env.NEXT_PUBLIC_LT_HOME);
      }
    } else if (DispatcherToken) {
      router.replace(process.env.NEXT_PUBLIC_DISPATCHER_HOME);
    } else {
      setWait(false);
    }
  }, []);
  return !wait && <div>{children}</div>;
};
export default Layout2;
