import React, { useState, useEffect } from "react";
import Icon from "../Icon";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { setLTMenu } from "../../Redux/Actions/sidebarAction";

export default function LtSideBar({ callback, isCustomCheck, setIsCustom }) {
  const router = useRouter();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const { LTuser } = useSelector((state) => state.ltUser);
  const Menu = useSelector((state) => state.sideBarMenu.ltMenu);

  useEffect(() => {
    let updatedMenu = [
      {
        id: 1,
        isOpen: false,
        title: "Companies",
        icon: "icon-dashboard-inactive",
      },
      {
        id: 2,
        isOpen: false,
        title: "Dashboard",
        icon: "icon-dashboard-inactive",
      },
      {
        id: 3,
        isOpen: false,
        title: "Learning",
        icon: "icon-lt-learning",
      },
    ];

    if (process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck") {
      updatedMenu.push({
        id: 4,
        isOpen: false,
        title: "Endorsements",
        icon: "icon-endorsement",
        subMenu: [
          {
            subTitle: "LT Endorsements",
            isActive: false,
          },
        ],
      });
    }

    if (
      process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck" &&
      (LTuser?.roles?.includes("SuperProducer") ||
        LTuser?.roles?.includes("Admin"))
    ) {
      updatedMenu = [
        {
          id: 1,
          isOpen: false,
          title: "Companies",
          icon: "icon-lt-logo",
        },
        {
          id: 2,
          isOpen: false,
          title: "Endorsements",
          icon: "icon-endorsement",
          subMenu: [
            {
              subTitle: "LT Endorsements",
              isActive: false,
            },
            {
              subTitle: "Stax Endorsements",
              isActive: false,
            },
          ],
        },
        {
          id: 3,
          isOpen: false,
          title: "Flexport Companies",
          icon: "icon-flexport-logo",
        },
        {
          id: 4,
          isOpen: false,
          title: "Dashboard",
          icon: "icon-dashboard-inactive",
        },
        {
          id: 5,
          isOpen: false,
          title:
            process.env.NEXT_PUBLIC_ORGANIZATION === "Stax"
              ? "Companies"
              : "Stax Companies",
          icon: "icon-dashboard-inactive",
        },
        {
          id: 6,
          isOpen: false,
          title: "Learning",
          icon: "icon-lt-learning",
        },
      ];
    }

    if (LTuser?.roles?.includes("Admin")) {
      updatedMenu.push({
        id: 7,
        isOpen: false,
        title: "Referral",
        icon: "icon-referral",
      });
    }

    dispatch(setLTMenu(updatedMenu));
  }, []);

  useEffect(() => {
    if (isCustomCheck) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isCustomCheck]);

  const handleSubMenuClick = (mainItem) => {
    if (!open && mainItem.title === "Endorsements") {
      setOpen(true);
      callback(!open);
    }

    let copySidebar = [...Menu];

    let updatedSidebar = copySidebar?.map((item) => {
      if (item.id === mainItem.id && item.title === "Endorsements") {
        return {
          ...item,
          isOpen: !item.isOpen,
        };
      } else if (item.id === mainItem.id) {
        return { ...item, isOpen: !item.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    dispatch(setLTMenu(updatedSidebar));

    if (mainItem.title === "Companies") {
      router.push(process.env.NEXT_PUBLIC_LT_COMPANIES);
    } else if (mainItem.title === "Endorsements") {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_ENDORSEMENTS);
    } else if (mainItem.title === "Flexport Companies") {
      router.push(process.env.NEXT_PUBLIC_FLEXPORT_COMPANIES);
    } else if (mainItem.title === "Stax Companies") {
      router.push(process.env.NEXT_PUBLIC_LT_STAX_COMPANIES);
    } else if (mainItem.title === "Dashboard") {
      router.push(process.env.NEXT_PUBLIC_LT_HOME);
    } else if (mainItem.title === "Learning") {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_LEARNING);
    } else if (mainItem.title === "Referral") {
      router.push("/LTteam" + process.env.NEXT_PUBLIC_USER_REFERRAL);
    }
  };

  const onClickSubMenu = (parentIndex, subMenuIndex) => {
    const updatedMenu = [...Menu];
    const updatedSubMenu = updatedMenu[parentIndex]?.subMenu?.map(
      (item, index) => ({
        ...item,
        isActive: index === subMenuIndex,
      })
    );
    updatedMenu[parentIndex] = {
      ...updatedMenu[parentIndex],
      subMenu: updatedSubMenu,
    };

    if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle ===
      "LT Endorsements"
    ) {
      router.push(process.env.NEXT_PUBLIC_LT_TEAM_ENDORSEMENTS);
    } else if (
      updatedMenu[parentIndex].subMenu[subMenuIndex].subTitle ===
      "Stax Endorsements"
    ) {
      router.push(process.env.NEXT_PUBLIC_LT_STAX_ENDORSEMENTS);
    }

    dispatch(setLTMenu(updatedMenu));
  };

  return (
    <div
      className={`flex flex-col h-full bg-gray4 rounded-r-lg fixed z-30 flex-wrap transition-all duration-300 xs:hidden ${
        open ? " w-ltSideBar" : "w-20"
      }`}
    >
      <img
        src={`${!open ? "/Images/Tab-open.webp" : "/Images/Tab-close.webp"}`}
        onClick={() => {
          setOpen(!open);
          callback(!open);
          setIsCustom(false);
        }}
        alt="truck"
        className="absolute cursor-pointer -right-10 top-3 w-12 h-12"
      />

      <div className="mt-20">
        <ul className="flex flex-col" style={{ height: "85vh" }}>
          {Menu?.map((item, i) => (
            <>
              <li
                key={i}
                className={`text-white cursor-pointer mt-5 relative ${
                  item.isOpen && open && "onhover"
                } `}
                onClick={() => handleSubMenuClick(item)}
              >
                {item.isOpen && (
                  <div
                    className={`bg-blue5 w-2 h-8 rounded-l-none rounded-r-md absolute -top-1 ${
                      open ? "-left-5" : "left-0"
                    }`}
                  />
                )}
                <div
                  className={`flex items-center space-x-4 ${
                    !open ? "ml-6" : "ml-10"
                  }`}
                >
                  <Icon name={item.icon} />
                  <h3
                    className={`${!open && "hidden"} ${
                      item.isOpen ? "title-sidebar-show" : "title-sidebar"
                    } `}
                  >
                    {item.title}
                  </h3>
                  {item.subMenu && open && (
                    <Icon
                      name={item.isOpen ? "icon-close-menu" : "icon-open-menu"}
                    />
                  )}
                </div>
              </li>

              {item.subMenu && open && (
                <ul
                  className={`flex flex-col subMenu ${
                    item.isOpen && "showMenu"
                  }`}
                >
                  {item?.subMenu?.map((subMenu, subIndex) => {
                    return (
                      <li
                        key={subIndex}
                        onClick={() => onClickSubMenu(i, subIndex)}
                        className="relative  text-white  mt-3 cursor-pointer ml-24"
                      >
                        <div className="flex items-center">
                          <h3
                            className={`${subMenu.isActive && "subMenuActive"}`}
                          >
                            {subMenu.subTitle}
                          </h3>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          ))}
        </ul>
      </div>
    </div>
  );
}
