import AuthReducer from "./auth";
import TabReducer from "./tabReducer";
import { combineReducers } from "redux";
import QuoteReducer from "./quotesReducer";
import ltUserReducer from "./ltUserReducer";
import policyReducer from "./policyReducer";
import CompanyReducer from "./companyReducer";
import sidebarReducer from "./sidebarReducer";
import driversReducer from "./driversReducer";
import EldProviderReducer from "./eldProvider";
import vehiclesReducer from "./vehiclesReducer";
import referralReducer from "./referralReducer";
import CommodityReducer from "./commodityReducer";
import dispatcherReducer from "./dispatcherReducer";
import EndorsementReducer from "./endorsementReducer";
import notificationsReducer from "./notificationsReducer";

const rootReducer = combineReducers({
  tab: TabReducer,
  tab: TabReducer,
  auth: AuthReducer,
  ltUser: ltUserReducer,
  policy: policyReducer,
  company: CompanyReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  referral: referralReducer,
  quoteReducer: QuoteReducer,
  sideBarMenu: sidebarReducer,
  commodities: CommodityReducer,
  dispatcher: dispatcherReducer,
  eldProvider: EldProviderReducer,
  endorsement: EndorsementReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
