import { createSelector } from 'reselect';

const selectNotifications = state => state.notifications;

export const selectTransformedNotifications = createSelector(
    [selectNotifications],
    notifications => {
        notifications && notifications.notifications?.length > 0 ? notifications.notifications?.map(n => {
            if (n.seenBy) {
                if (typeof window !== 'undefined') {
                    if (!n.seenBy.includes(JSON.parse(localStorage.getItem('userData'))?.id)) {
                        n['seen'] = false
                    }
                    else {
                        n['seen'] = true
                    }
                }
            }
            return n
        }) : []

        return notifications
    }
)

export const selectHomeNotifications = createSelector(
    [selectNotifications],
    notifications => notifications.homeNotifications
)

export const selectHomeFetchMore = createSelector(
    [selectNotifications],
    notifications =>notifications.homeFetchMore
)

export const selectHomeViewMoreLoader = createSelector(
    [selectNotifications],
    notifications => notifications.homeViewMoreLoader
)
export const selectListPage = createSelector(
    [selectNotifications],
    notifications => notifications.listPage
)
export const selectHomeListPage = createSelector(
    [selectNotifications],
    notifications => notifications.homeListPage
)
export const selectFetchMore = createSelector(
    [selectNotifications],
    notifications => notifications.fetchMore
)

export const selectViewMoreLoader = createSelector(
    [selectNotifications],
    notifications => notifications.viewMoreLoader
)