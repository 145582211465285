export const setPastQuote = (payload) => {
  return payload?.filter((d) => d.isDeleted === true);
};

export const greatAmericanCommoditiesValidationPromise = async (
  commodities
) => {
  return new Promise((resolve, reject) => {
    let isValid = true;
    for (var i = 0; i < commodities?.length; i++) {
      if (commodities[i].subType === "commodity-aircraftEngines") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-auto/wrekService") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-liquor") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-boats") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-bullion") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-butter") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-candy") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-tapes") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-glassware") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-tobaccoProducts") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-athleticClothing") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-blueJeans") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-furs") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-designerClothing") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-teeShirts") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-oversizedEquipment") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-over300MileFreight") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-copper") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-eggs") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-electronicEquip") {
        isValid = false;
      } else if (
        commodities[i].subType === "commodity-oversizedFarmMachinery"
      ) {
        isValid = false;
      } else if (commodities[i].subType === "commodity-cable") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-fineArts") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-fireArms") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-flowers") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-glass") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-iceCream") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-jewelery") {
        isValid = false;
      } else if (
        commodities[i].subType === "commodity-liverstockOver300Miles"
      ) {
        isValid = false;
      } else if (commodities[i].subType === "commodity-heavyMachinery") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-lightMachineryFlabBed") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-imagingUnits") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-meatSwinging") {
        isValid = false;
      } else if (
        commodities[i].subType === "commodity-expensiveMedicalEquipment"
      ) {
        isValid = false;
      } else if (commodities[i].subType === "commodity-collectibles") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-mobileHomes") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-money") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-motorcycles") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-orientalRugs") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-overweightItems") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-perfume") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-videoEquip") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-pianos") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-preciousMetals") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-recreationalVehicles") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-petrolProds") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-rigging") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-seafoodFresh") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-seafoodFrozen") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-securities") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-shoes") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-spasCommercial") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-stoneProducts") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-swimmingPools") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-tires") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-transformers") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-turbines") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-tissue") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-pharmaceuticalsOTC") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-houseGoods") {
        isValid = false;
      } else if (commodities[i].subType === "commodity-lightMachineryFlatBed") {
        isValid = false;
      } else if (
        commodities[i].subType === "commodity-pharmaceuticalsPrescription"
      ) {
        isValid = false;
      } else if (
        commodities[i].subType === "commodity-generalFreight" &&
        commodities[i].percentage > 25
      ) {
        isValid = false;
      } else if (commodities[i].subType === "commodity-otherCommodity") {
        isValid = false;
      }
    }
    resolve(isValid);
  });
};

export const updateApplicationInApplications = (applications, payload) => {
  const _applications =
    applications && applications?.length > 0 ? [...applications] : [];
  const index = _applications.findIndex((a) => a.id == payload.id);
  if (index > -1) {
    _applications[index] = payload;
  }
  return _applications;
};

export const updateApplicationPackage = (applications, payload) => {
  const _applications =
    applications && applications?.length > 0 ? [...applications] : [];
  const __applications = _applications?.map((app) => {
    const index = app.package.findIndex((pack) => pack.id == payload.id);
    if (index > -1) {
      app.package[index] = payload;
    }
    return app;
  });
  return [...__applications];
};

export const updateApplicationPackageForFile = (applications, payload) => {
  const _applications =
    applications && applications?.length > 0 ? [...applications] : [];
  const __applications = _applications?.map((app) => {
    const index = app.package.findIndex((pack) => pack.id == +payload.id);
    if (index > -1) {
      payload.Files.forEach((file) => {
        app.package[index].quote = app.package[index]?.quote?.map((q) => {
          const _quoteFiles = [...q.File];

          _quoteFiles.push(file);
          q.File = _quoteFiles;
          return q;
        });
      });
    }
    return app;
  });

  return [...__applications];
};

export const defaultCurrentApplicationObject = {
  id: null,
  lobs: "",
  stage: "Prospective",
  appFlow: "Normal",
  activeAuth: false,
  doTheseApply: false,
  doTheseApplyCw: false,
  haul: undefined,
  operationsDescription: null,
  driversAbove23: null,
  cdlExperience: null,
  anySeriousViolations: null,
  currentProgCust: false,
  businessStructure: null,
  preferedCarrier: null,
  driverSummary: null,
  priorityStatus: null,
  totalValueTrucks: null,
  totalValueTrailers: null,
  grossAnnualTruckRev: null,
  annualTotalMileage: null,
  haulsUnder50Miles: 0,
  haulsUnder200Miles: 0,
  haulsUnder500Miles: 0,
  haulsAbove500Miles: 0,
  currentlyInsured: false,
  otherCoverages: null,
  totalAdditionalInsureds: null,
  totalNamedInsureds: null,
  blanketAdditionalNeeded: false,
  blanketWaiverSubrogations: false,
  stateOrFederalFilingsReq: null,
  snapshotPreview: false,
  effectiveDate: new Date().toISOString(),
  towingLaborStorageLimit: null,
  trailerInterchangeLimit: null,
  autoLiabilityLossCount: null,
  autoLiabilityLossPaid: null,
  physicalDamageLossCount: null,
  physicalDamageLossPaid: null,
  cargoLossCount: null,
  cargoLossPaid: null,
  truckerGenLiabLossCount: null,
  truckerGenLiabLossPaid: null,
  lossesConfirmedVsPending: null,
  createdAt: null,
  updatedAt: null,
  isTest: false,
  currentPage: null,
  numberDrivers: null,
  numberPowerUnits: null,
  avgRadOps: null,
  domiciledWithin50Miles: false,
  limitInsPerPowerUnit: null,
  perOccurenceLimit: null,
  deductible: null,
  spoilageFreezingCoverage: false,
  mtcAdditionalCoverage: false,
  pollutionCleanUp: false,
  ownersGoodExtension: false,
  nonOwnedTrailer: false,
  expirationDate: new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).toISOString(),
  isThereRigging: null,
  status: null,
  numberLosses3Years: null,
  averageViolations: null,
  totalViolations: null,
  disclaimer: null,
  limitInsPerPowerUnitNonOwned: null,
  docusignUrl: null,
  typeOfOps: null,
  typeOfOpsDescriptionOther: null,
  typeOfOpsFao: null,
  glOrBop: null,
  yearCurrentBusiness: null,
  limitAl: null,
  limitPd: null,
  limitMtc: null,
  limitGl: null,
  limitNtl: null,
  trailerInterchangeDeductible: null,
  deductibleAl: null,
  deductiblePd: null,
  deductibleMtc: null,
  deductibleGl: null,
  deductibleNtl: null,
  numberLossesPast3YearsGA: null,
  continuousCoverage: false,
  DOTObtain60Days: null,
  typeOfTrucker: null,
  ineligibleOperations: false,
  vehicleHaulOilAndGas: false,
  hazardousMaterialPlacard: false,
  isCEODriver: false,
  isCEOInvolvedInOperations: false,
  bodilyInjuryLiabilityLimit: null,
  otherGlOrBop: null,
  priorInsuranceCancelledOrRenewed: false,
  seriousViolationsWithinPast5Years: null,
  ELDVendorName: null,
  LossControlTechnology: null,
  ELDRequiredToRecord: null,
  commercialVehiclesInsuredOwns: false,
  privatePassengerAutosInsured: false,
  insuredVehiclesUsesInBusiness: false,
  federal: false,
  federalCargo: false,
  progressiveState: null,
  progressiveStateCargo: null,
  progressiveOther: null,
  progressiveEligibilityCriteria: false,
  progressivePolicyState: null,
  progressiveELDVendor: null,
  ELDVendorAccess: false,
  nonOwnedTrailerPhysicalDamage: null,
  MCS90: false,
  isGAKickOut: null,
  isCWKickOut: null,
  cwLosses: null,
  motorTruckCargoLimit: null,
  towingLaborStorageLimitstr: null,
  trailerInterchangeLimitstr: null,
  indications: [],
  isProgKickOut: null,
  vehiclesHualSteel: false,
  vehiclesTowingOrHauling: false,
  previousClaimCw: false,
  totalAccidents: 0,
  totalNumberViolations: 0,
  totalSuspensions: 0,
  primaryAndNoncontributoryForTGL: false,
  primaryAndNoncontributoryForAL: false,
  requireFederalFillingForAL: false,
  requireStateFillingForAL: false,
  hauledRefrigeratedGoods: false,
  ownerFname: null,
  ownerLname: null,
  ownerEmail: null,
  ownerDob: null,
  appStack: "[]",
  forHireTruckingOps: false,
  operateAWarehouse: false,
  setUpOrInstallation: false,
  frackingGasProd: false,
  bizOutsideOfForHireOps: false,
  progGLLimit: null,
  // nico object properties
  insuredWithNICO: null,
  policyNumbers: null,
  effectiveDates: null,
  newVenture: null,
  towTruckRepossessions: null,
  primaryBusiness: null,
  ifNotPrimaryExplain: null,
  Seasonal: null,
  filedForBankruptcy: null,
  whenFilledForBankruptcy: new Date().toISOString(),
  explainForBankruptcy: null,
  GrossReceiptsLastYear: null,
  EstimateForComingYear: null,
  BusinessForSale: null,
  operateMoreThanOneState: null,
  listStates: null,
  largestCitiesEntered: null,
  haulHazardousMaterialByEPA: null,
  listingForChemicalMaterials: null,
  hireVehicles: null,
  driversCoveredCompensation: null,
  ifYesNameOfCarrier: null,
  minYearsDrivingExpRequired: null,
  vehiclesOwnerDriven: null,
  driversAllowedToTakeVehiclesHome: null,
  maxDrivingHoursDaily: null,
  maxDrivingHoursWeekly: null,
  driverPayBasis: null,
  lessorBeAdded: null,
  nameAddressOfLessorForEachVehicle: null,
  anyLossPayees: null,
  nameAddressOfMortgageeForEachVehicle: null,
  applicantAwareOfClaim: null,
  provideCompleteDetails: null,
  everBeenDeclined: null,
  ifYesDateAndWhy: null,
  additionalCoverageOptions: null,
  intrastateFilingNeeded: null,
  stateAndPermitNumber: null,
  stateListForCargoFilings: null,
  nameAndAddressPermitIssued: null,
  MCS90EndorsementNeeded: null,
  overWeightCommoditiesHauled: null,
  ifFillingRequiredShowStates: null,
  escortVehiclesTowed: null,
  allowOthersToHaulUnderYourAuthority: null,
  allowOthersToHaulHazardousCommodities: null,
  everChangedYourOperatingName: null,
  operateAnyOtherName: null,
  leaseYourAuthority: null,
  appointAgentsOnYourBehalf: null,
  appliedForAuthorityPast3Years: null,
  authorityWithdrawn: null,
  evidenceRequired: null,
  explainForAboveMultipleBooleanYes: null,
  agreementsWithOtherCarriers: null,
  withWhomAgreementHasBeenMade: null,
  partiesNamedAutoMobilesLiability: null,
  nameOfInsuranceCompanyAndLimitOfLiability: null,
  whosePermitOtherPartiesOperate: null,
  holdHarmlessInAgreement: null,
  barterOrHireVehicles: null,
  premiumBeFinanced: null,

  // other app fileds
  filingState: null,
  stateFilingsType: null,
  snapShotProView: false,
  proViewVehicleAnnualMileage: false,
  proViewPolicyState: null,
  proViewTermsAndConditions: false,
  cargoReqForMobileHomes: false,
  cargoReqForBizDocsOrSecs: false,
  fridgeBreakdownCoverage: false,
  progMTCLimit: null,
  compDeductible: null,
  collDeductible: null,
  vehicleLeaseProvidePrimaryLiability: null,
  progNTLLimit: null,
  progNonOwnedTrailerPDLimit: null,
  customerSpendLastYear: null,
  nonOwnedVehiclesUsedInBusiness: false, //Progressive theme 2 data point
  cargoCoverageForMobileHomes: false,
  progressiveTrailerInterchangeCoverage: false,
  monthInBusiness: null,
  typeOfOpsCW: [],
  edited: false,
  progCurrentPolicyexpirationDate: null,
  coverWhaleOptAlUm: false,
  coverWhaleOptAlPip: false,
  authorityNumber: null,

  // great American API changes : June 16 2023
  tarpsAndChains: false,
  reusablePackingContainers: false,
  subHaulerOwnerOrOperator: false,
  numberOfNonOwnedTrailers: null,
  trailerInterchangeFurnishAgreement: false,
  trailerInterchangeLimitProgressive: null,
  debrisRemovalLimitGA: null,
  reloadingLimitGA: null,
  rewardCoverageLimitGA: null,
  earnedFreightLimitGA: null,
  fireDepartmentServiceChargesLimitGA: null,
  lossDataPreparationLimitGA: null,
  tarpsAndChainsLimitGA: null,
  reusablePackingContainersLimitGA: null,
  annualValueShippedGA: null,
};
